import Property1 from "../assets/images/property/1.jpg";
import Property2 from "../assets/images/property/2.jpg";
import Property3 from "../assets/images/property/3.jpg";
import Property4 from "../assets/images/property/4.jpg";
import Property5 from "../assets/images/property/5.jpg";
import Property6 from "../assets/images/property/6.jpg";
import Property7 from "../assets/images/property/7.jpg";
import Property8 from "../assets/images/property/8.jpg";
import Property9 from "../assets/images/property/9.jpg";
import Property10 from "../assets/images/property/10.jpg";
import Property11 from "../assets/images/property/11.jpg";
import Property12 from "../assets/images/property/12.jpg";

import Hc from "../assets/images/HappyCustomers/sai-1/hc1.png";
import Hc1 from "../assets/images/HappyCustomers/sai-1/hc2.png";
import Hc2 from "../assets/images/HappyCustomers/sai-1/hc3.png";
import Hc3 from "../assets/images/HappyCustomers/sai-1/hc4.png";
import Hc4 from "../assets/images/HappyCustomers/sai-1/hc5.png";
import Hc5 from "../assets/images/HappyCustomers/sai-1/hc6.png";
import Hc6 from "../assets/images/HappyCustomers/sai-1/hc7.png";
import Hc7 from "../assets/images/HappyCustomers/sai-1/hc8.png";
import Hc8 from "../assets/images/HappyCustomers/sai-1/hc9.png";
import Hc9 from "../assets/images/HappyCustomers/sai-1/hc10.png";
import Hc10 from "../assets/images/HappyCustomers/sai-1/hc11.png";
import Hc11 from "../assets/images/HappyCustomers/sai-1/hc12.png";
import Hc12 from "../assets/images/HappyCustomers/sai-1/hc13.png";
import Hc13 from "../assets/images/HappyCustomers/sai-1/hc14.png";
import Hc14 from "../assets/images/HappyCustomers/sai-1/hc15.png";
import Hc16 from "../assets/images/HappyCustomers/sai-1/hc16.png";
import Hc15 from "../assets/images/HappyCustomers/sai-1/hc18.png";
import Hc17 from "../assets/images/HappyCustomers/sai-1/hc17.png";
import Hc18 from "../assets/images/HappyCustomers/sai-1/hc18.png";
import Hc20 from "../assets/images/HappyCustomers/sai-1/hcc-20.jpg";
import Hc21 from "../assets/images/HappyCustomers/sai-1/hcc-21.jpg";
import Hc22 from "../assets/images/HappyCustomers/sai-1/hcc-22.jpg";
import Hc23 from "../assets/images/HappyCustomers/sai-1/hcc-23.jpg";
import Hc24 from "../assets/images/HappyCustomers/sai-1/hcc-24.jpg";
import Hc25 from "../assets/images/HappyCustomers/sai-1/hcc-25.jpg";
import Hc26 from "../assets/images/HappyCustomers/sai-1/hcc-26.jpg";
import Hc27 from "../assets/images/HappyCustomers/sai-1/hcc-27.jpg";
import Hc28 from "../assets/images/HappyCustomers/sai-1/hcc-28.jpg";
import Hc29 from "../assets/images/HappyCustomers/sai-1/hcc-29.jpg";
import Hc30 from "../assets/images/HappyCustomers/sai-1/hcc-30.jpg";
import Hc31 from "../assets/images/HappyCustomers/sai-1/hcc-31.jpg";
import Hc32 from "../assets/images/HappyCustomers/sai-1/hcc-32.jpg";
import Hc33 from "../assets/images/HappyCustomers/sai-1/hcc-33.jpg";
import Hc34 from "../assets/images/HappyCustomers/sai-1/hcc-34.jpg";
import Hc35 from "../assets/images/HappyCustomers/sai-1/hcc-35.jpg";
import Hc36 from "../assets/images/HappyCustomers/sai-1/hcc-36.jpg";
import Hc37 from "../assets/images/HappyCustomers/sai-1/hcc-37.jpg";
import Hc38 from "../assets/images/HappyCustomers/sai-1/hcc-38.jpg";
import Hc39 from "../assets/images/HappyCustomers/sai-1/hcc-39.jpg";
import Hc40 from "../assets/images/HappyCustomers/sai-1/hcc-40.jpg";
import Hc41 from "../assets/images/HappyCustomers/sai-1/hcc-41.jpg";
import Hc42 from "../assets/images/HappyCustomers/sai-1/hcc-42.jpg";
import Hc43 from "../assets/images/HappyCustomers/sai-1/hcc-43.jpg";
import Hc44 from "../assets/images/HappyCustomers/sai-1/hcc-44.jpg";
import Hc45 from "../assets/images/HappyCustomers/sai-1/hcc-46.jpeg";
import Hc46 from "../assets/images/HappyCustomers/sai-1/hcc-47.jpeg";
import Hc47 from "../assets/images/HappyCustomers/sai-1/hcc-48.jpeg";
import Hc48 from "../assets/images/HappyCustomers/sai-1/hcc-49.jpeg";
import Hc49 from "../assets/images/HappyCustomers/sai-1/hcc-50.jpeg";
import Hc50 from "../assets/images/HappyCustomers/sai-1/hcc-51.jpeg";
import Hc51 from "../assets/images/HappyCustomers/sai-1/hcc-52.jpeg";
import Hc52 from "../assets/images/HappyCustomers/sai-1/hcc-53.jpeg";
import Hc53 from "../assets/images/HappyCustomers/sai-1/hcc-54.jpeg";
import Hc54 from "../assets/images/HappyCustomers/sai-1/hcc-55.jpeg";
import Hc55 from "../assets/images/HappyCustomers/sai-1/hcc-56.jpeg";
import Hc56 from "../assets/images/HappyCustomers/sai-1/hcc-57.jpeg";
import Hc57 from "../assets/images/HappyCustomers/sai-1/hcc-58.jpeg";
import Hc58 from "../assets/images/HappyCustomers/sai-1/hcc-59.jpeg";
import Hc59 from "../assets/images/HappyCustomers/sai-1/hcc-60.jpeg";
import Hc60 from "../assets/images/HappyCustomers/sai-1/hcc-61.jpeg";
import Hc61 from "../assets/images/HappyCustomers/sai-1/hcc-62.jpeg";
import Hc62 from "../assets/images/HappyCustomers/sai-1/hcc-63.jpeg";
import Hc63 from "../assets/images/HappyCustomers/sai-1/hcc-64.jpeg";
import Hc64 from "../assets/images/HappyCustomers/sai-1/hcc-65.jpeg";
import Hc65 from "../assets/images/HappyCustomers/sai-1/hcc-66.jpeg";
import Hc66 from "../assets/images/HappyCustomers/sai-1/hcc-67.jpeg";
import Hc67 from "../assets/images/HappyCustomers/sai-1/hcc-68.jpeg";
import Hc68 from "../assets/images/HappyCustomers/sai-1/hcc-69.jpeg";
import Hc69 from "../assets/images/HappyCustomers/sai-1/hcc-70.jpeg";
import Hc70 from "../assets/images/HappyCustomers/sai-1/hcc-71.jpeg";
import Hc71 from "../assets/images/HappyCustomers/sai-1/hcc-72.jpeg";
import Hc72 from "../assets/images/HappyCustomers/sai-1/hcc-73.jpeg";
import Hc73 from "../assets/images/HappyCustomers/sai-1/hcc-74.jpeg";
import Hc74 from "../assets/images/HappyCustomers/sai-1/hcc-75.jpeg";
import Hc75 from "../assets/images/HappyCustomers/sai-1/hcc-76.jpeg";
import Hc76 from "../assets/images/HappyCustomers/sai-1/hcc-77.jpeg";
import Hc77 from "../assets/images/HappyCustomers/sai-1/hcc-78.jpeg";
import Hc78 from "../assets/images/HappyCustomers/sai-1/hcc-78.jpeg";
import Hc79 from "../assets/images/HappyCustomers/sai-1/hcc-79.jpeg";
import Hc80 from "../assets/images/HappyCustomers/sai-1/hcc-80.jpeg";
import Hc81 from "../assets/images/HappyCustomers/sai-1/hcc-81.jpeg";
import Hc82 from "../assets/images/HappyCustomers/sai-1/hcc-82.jpeg";
import Hc83 from "../assets/images/HappyCustomers/sai-1/hcc-83.jpeg";
import Hc84 from "../assets/images/HappyCustomers/sai-1/hcc-84.jpeg";
import Hc85 from "../assets/images/HappyCustomers/sai-1/hcc-85.jpeg";
import Hc86 from "../assets/images/HappyCustomers/sai-1/hcc-86.jpeg";
import Hc87 from "../assets/images/HappyCustomers/sai-1/hcc-87.jpeg";
import Hc88 from "../assets/images/HappyCustomers/sai-1/hcc-88.jpeg";
import Hc89 from "../assets/images/HappyCustomers/sai-1/hcc-89.jpeg";
import Hc90 from "../assets/images/HappyCustomers/sai-1/hcc-90.jpeg";
import Hc91 from "../assets/images/HappyCustomers/sai-1/hcc-91.jpeg";
import Hc92 from "../assets/images/HappyCustomers/sai-1/hcc-92.jpeg";
import Hc93 from "../assets/images/HappyCustomers/sai-1/hcc-93.jpeg";
import Hc94 from "../assets/images/HappyCustomers/sai-1/hcc-94.jpeg";
import Hc95 from "../assets/images/HappyCustomers/sai-1/hcc-95.jpeg";
import Hc96 from "../assets/images/HappyCustomers/sai-1/hcc-96.jpeg";
import Hc97 from "../assets/images/HappyCustomers/sai-1/hcc-97.jpeg";
import Hc98 from "../assets/images/HappyCustomers/sai-1/hcc-98.jpeg";
import Hc99 from "../assets/images/HappyCustomers/sai-1/hcc-99.jpeg";
import Hc100 from "../assets/images/HappyCustomers/sai-1/hcc-100.jpeg";

/* SR2 Hcustomers*/

import Hcc1 from "../assets/images/HappyCustomers/sai-2/hcc1.jpeg";
import Hcc2 from "../assets/images/HappyCustomers/sai-2/hcc2.jpeg";
import Hcc3 from "../assets/images/HappyCustomers/sai-2/hcc3.jpeg";
import Hcc4 from "../assets/images/HappyCustomers/sai-2/hcc4.jpeg";
import Hcc5 from "../assets/images/HappyCustomers/sai-2/hcc5.jpeg";
import Hcc6 from "../assets/images/HappyCustomers/sai-2/hcc6.jpeg";
import Hcc7 from "../assets/images/HappyCustomers/sai-2/hcc7.jpeg";
import Hcc8 from "../assets/images/HappyCustomers/sai-2/hcc8.jpeg";
import Hcc9 from "../assets/images/HappyCustomers/sai-2/hcc9.jpeg";
import Hcc10 from "../assets/images/HappyCustomers/sai-2/hcc10.jpeg";
import Hcc11 from "../assets/images/HappyCustomers/sai-2/hcc11.jpeg";
import Hcc12 from "../assets/images/HappyCustomers/sai-2/hcc12.jpeg";
import Hcc13 from "../assets/images/HappyCustomers/sai-2/hcc13.jpeg";
import Hcc14 from "../assets/images/HappyCustomers/sai-2/hcc14.jpeg";
import Hcc15 from "../assets/images/HappyCustomers/sai-2/hcc15.jpeg";
import Hcc16 from "../assets/images/HappyCustomers/sai-2/hcc16.jpeg";
import Hcc17 from "../assets/images/HappyCustomers/sai-2/hcc17.jpeg";
import Hcc18 from "../assets/images/HappyCustomers/sai-2/hcc18.jpeg";
import Hcc19 from "../assets/images/HappyCustomers/sai-2/hcc19.jpeg";
import Hcc20 from "../assets/images/HappyCustomers/sai-2/hcc20.jpeg";
import Hcc21 from "../assets/images/HappyCustomers/sai-2/hcc21.jpeg";
import Hcc22 from "../assets/images/HappyCustomers/sai-2/hcc22.jpeg";
import Hcc23 from "../assets/images/HappyCustomers/sai-2/hcc23.jpeg";
import Hcc24 from "../assets/images/HappyCustomers/sai-2/hcc24.JPG";
import Hcc25 from "../assets/images/HappyCustomers/sai-2/hcc25.JPG";
import Hcc26 from "../assets/images/HappyCustomers/sai-2/hcc26.JPG";
import Hcc27 from "../assets/images/HappyCustomers/sai-2/hcc27.JPG";
import Hcc28 from "../assets/images/HappyCustomers/sai-2/hcc28.JPG";
import Hcc29 from "../assets/images/HappyCustomers/sai-2/hcc29.JPG";
import Hcc30 from "../assets/images/HappyCustomers/sai-2/hcc30.JPG";
import Hcc31 from "../assets/images/HappyCustomers/sai-2/hcc31.JPG";
import Hcc32 from "../assets/images/HappyCustomers/sai-2/hcc32.JPG";
import Hcc33 from "../assets/images/HappyCustomers/sai-2/hcc33.JPG";
import Hcc34 from "../assets/images/HappyCustomers/sai-2/hcc34.JPG";
import Hcc35 from "../assets/images/HappyCustomers/sai-2/hcc35.JPG";
import Hcc36 from "../assets/images/HappyCustomers/sai-2/hcc36.JPG";
import Hcc37 from "../assets/images/HappyCustomers/sai-2/hcc37.JPG";
import Hcc38 from "../assets/images/HappyCustomers/sai-2/hcc38.JPG";
import Hcc39 from "../assets/images/HappyCustomers/sai-2/hcc39.JPG";
import Hcc40 from "../assets/images/HappyCustomers/sai-2/hcc40.JPG";
import Hcc41 from "../assets/images/HappyCustomers/sai-2/hcc41.JPG";
import Hcc42 from "../assets/images/HappyCustomers/sai-2/hcc42.JPG";
import Hcc43 from "../assets/images/HappyCustomers/sai-2/hcc43.JPG";
import Hcc44 from "../assets/images/HappyCustomers/sai-2/hcc44.JPG";
import Hcc45 from "../assets/images/HappyCustomers/sai-2/hcc45.JPG";
import Hcc46 from "../assets/images/HappyCustomers/sai-2/hcc46.JPG";
import Hcc47 from "../assets/images/HappyCustomers/sai-2/hcc47.JPG";
import Hcc48 from "../assets/images/HappyCustomers/sai-2/hcc48.JPG";
import Hcc49 from "../assets/images/HappyCustomers/sai-2/hcc49.JPG";
import Hcc50 from "../assets/images/HappyCustomers/sai-2/hcc50.JPG";
import Hcc51 from "../assets/images/HappyCustomers/sai-2/hcc51.JPG";
import Hcc52 from "../assets/images/HappyCustomers/sai-2/hcc52.JPG";
import Hcc53 from "../assets/images/HappyCustomers/sai-2/hcc53.JPG";
import Hcc54 from "../assets/images/HappyCustomers/sai-2/hcc54.JPG";
import Hcc55 from "../assets/images/HappyCustomers/sai-2/hcc55.JPG";
import Hcc56 from "../assets/images/HappyCustomers/sai-2/hcc56.JPG";
import Hcc57 from "../assets/images/HappyCustomers/sai-2/hcc57.JPG";
import Hcc58 from "../assets/images/HappyCustomers/sai-2/hcc58.JPG";
import Hcc59 from "../assets/images/HappyCustomers/sai-2/hcc59.JPG";
import Hcc60 from "../assets/images/HappyCustomers/sai-2/hcc60.JPG";
import Hcc61 from "../assets/images/HappyCustomers/sai-2/hcc61.JPG";
import Hcc62 from "../assets/images/HappyCustomers/sai-2/hcc62.JPG";
import Hcc63 from "../assets/images/HappyCustomers/sai-2/hcc63.JPG";
import Hcc64 from "../assets/images/HappyCustomers/sai-2/hcc64.JPG";
import Hcc65 from "../assets/images/HappyCustomers/sai-2/hcc65.JPG";

import Hcc66 from "../assets/images/HappyCustomers/sai-2/hcc66.JPG";
import Hcc67 from "../assets/images/HappyCustomers/sai-2/hcc67.JPG";
import Hcc68 from "../assets/images/HappyCustomers/sai-2/hcc68.JPG";
import Hcc69 from "../assets/images/HappyCustomers/sai-2/hcc69.JPG";
import Hcc70 from "../assets/images/HappyCustomers/sai-2/hcc70.JPG";
import Hcc71 from "../assets/images/HappyCustomers/sai-2/hcc71.JPG";
import Hcc72 from "../assets/images/HappyCustomers/sai-2/hcc72.JPG";
import Hcc73 from "../assets/images/HappyCustomers/sai-2/hcc73.JPG";
import Hcc74 from "../assets/images/HappyCustomers/sai-2/hcc74.JPG";
import Hcc75 from "../assets/images/HappyCustomers/sai-2/hcc75.JPG";
import Hcc76 from "../assets/images/HappyCustomers/sai-2/hcc76.JPG";
import Hcc77 from "../assets/images/HappyCustomers/sai-2/hcc77.JPG";
import Hcc78 from "../assets/images/HappyCustomers/sai-2/hcc78.JPG";
import Hcc79 from "../assets/images/HappyCustomers/sai-2/hcc79.JPG";
import Hcc80 from "../assets/images/HappyCustomers/sai-2/hcc80.JPG";
import Hcc81 from "../assets/images/HappyCustomers/sai-2/hcc81.JPG";
import Hcc82 from "../assets/images/HappyCustomers/sai-2/hcc82.JPG";
import Hcc83 from "../assets/images/HappyCustomers/sai-2/hcc83.JPG";
import Hcc84 from "../assets/images/HappyCustomers/sai-2/hcc84.JPG";
import Hcc85 from "../assets/images/HappyCustomers/sai-2/hcc85.JPG";
import Hcc86 from "../assets/images/HappyCustomers/sai-2/hcc86.JPG";
import Hcc87 from "../assets/images/HappyCustomers/sai-2/hcc87.JPG";
import Hcc88 from "../assets/images/HappyCustomers/sai-2/hcc88.JPG";

/* SR2 Hcustomers*/

/* SR3 Happy Customer */

import HCC1 from "../assets/images/HappyCustomers/sai-3/hcc1.JPG";
import HCC2 from "../assets/images/HappyCustomers/sai-3/hcc2.JPG";
import HCC3 from "../assets/images/HappyCustomers/sai-3/hcc3.JPG";

/* SR3 Happy Customer */

/* plan Elevation */
import Image1 from "../assets/images/property/single/p-1.png";
import Image2 from "../assets/images/property/single/p-2.png";
import Image3 from "../assets/images/property/single/p-3.png";
import Image4 from "../assets/images/property/single/p-4.png";
import Image5 from "../assets/images/property/single/p-5.png";

import Ld1 from "../assets/images/Land-Developement/Ld1.png";
import Ld2 from "../assets/images/Land-Developement/Ld2.png";
import Ld3 from "../assets/images/Land-Developement/Ld3.png";
import Ld4 from "../assets/images/Land-Developement/Ld4.png";
import Ld5 from "../assets/images/Land-Developement/Ld5.png";
import Ld6 from "../assets/images/Land-Developement/Ld6.png";
import Ld7 from "../assets/images/Land-Developement/Ld7.png";
import Ld8 from "../assets/images/Land-Developement/ld8.png";
import Ld9 from "../assets/images/Land-Developement/ld10.png";
import Ld10 from "../assets/images/Land-Developement/ld11.png";
import Ld11 from "../assets/images/Land-Developement/ld12.png";
import Ld12 from "../assets/images/Land-Developement/ld13.png";
import Ld13 from "../assets/images/Land-Developement/ld14.png";
import Ld14 from "../assets/images/Land-Developement/ld15.png";
import Ld15 from "../assets/images/Land-Developement/ld16.png";
import Ld17 from "../assets/images/Land-Developement/ld17.png";
import Ld18 from "../assets/images/Land-Developement/ld18.jpeg";
import Ld19 from "../assets/images/Land-Developement/ld19.jpeg";
import Ld20 from "../assets/images/Land-Developement/ld20.jpeg";
import Ld21 from "../assets/images/Land-Developement/ld21.jpeg";
import Ld22 from "../assets/images/Land-Developement/ld22.jpeg";
import Ld23 from "../assets/images/Land-Developement/ld23.jpeg";
import Ld24 from "../assets/images/Land-Developement/ld24.jpeg";
import Ld25 from "../assets/images/Land-Developement/ld25.jpeg";
import Ld26 from "../assets/images/Land-Developement/ld26.jpeg";
import Ld27 from "../assets/images/Land-Developement/ld27.jpeg";
import Ld28 from "../assets/images/Land-Developement/ld28.jpeg";
import Ld29 from "../assets/images/Land-Developement/ld29.jpeg";
import Ld30 from "../assets/images/Land-Developement/ld30.jpeg";
import Ld31 from "../assets/images/Land-Developement/ld31.jpeg";
import Ld32 from "../assets/images/Land-Developement/ld32.jpeg";
import Ld33 from "../assets/images/Land-Developement/ld33.jpeg";
import Ld34 from "../assets/images/Land-Developement/ld34.jpeg";
import Ld35 from "../assets/images/Land-Developement/ld35.jpeg";
import Ld36 from "../assets/images/Land-Developement/ld36.jpeg";
import Ld37 from "../assets/images/Land-Developement/ld37.jpeg";
import Ld38 from "../assets/images/Land-Developement/ld38.jpeg";
import Ld39 from "../assets/images/Land-Developement/ld39.jpeg";
import Ld40 from "../assets/images/Land-Developement/ld40.jpeg";
import Ld41 from "../assets/images/Land-Developement/ld41.jpeg";
import Ld42 from "../assets/images/Land-Developement/ld42.jpeg";
import Ld43 from "../assets/images/Land-Developement/ld43.jpeg";
import Ld44 from "../assets/images/Land-Developement/ld44.jpeg";
import Ld45 from "../assets/images/Land-Developement/ld45.jpeg";
import Ld46 from "../assets/images/Land-Developement/ld46.jpeg";
import Ld47 from "../assets/images/Land-Developement/ld47.jpeg";
import Ld48 from "../assets/images/Land-Developement/ld48.jpeg";
import Ld49 from "../assets/images/Land-Developement/ld49.jpeg";
import Ld50 from "../assets/images/Land-Developement/ld50.jpeg";
import Ld51 from "../assets/images/Land-Developement/ld51.jpeg";
import Ld52 from "../assets/images/Land-Developement/ld52.jpeg";
import Ld53 from "../assets/images/Land-Developement/ld53.jpeg";
import Ld54 from "../assets/images/Land-Developement/ld54.jpeg";
import Ld55 from "../assets/images/Land-Developement/ld55.jpeg";
import Ld56 from "../assets/images/Land-Developement/ld56.jpeg";
import Ld57 from "../assets/images/Land-Developement/ld57.jpeg";
import Ld58 from "../assets/images/Land-Developement/ld58.jpeg";
import Ld59 from "../assets/images/Land-Developement/ld59.jpeg";
import Ld60 from "../assets/images/Land-Developement/ld60.jpeg";
import Ld61 from "../assets/images/Land-Developement/ld61.jpeg";
import Ld62 from "../assets/images/Land-Developement/ld62.jpeg";
import Ld63 from "../assets/images/Land-Developement/ld63.jpeg";
import Ld64 from "../assets/images/Land-Developement/ld64.jpeg";
import Ld65 from "../assets/images/Land-Developement/ld65.jpeg";
import Ld66 from "../assets/images/Land-Developement/ld66.jpeg";
import Ld67 from "../assets/images/Land-Developement/ld67.jpeg";
import Ld68 from "../assets/images/Land-Developement/ld68.jpeg";
import Ld69 from "../assets/images/Land-Developement/ld69.jpeg";
import Ld70 from "../assets/images/Land-Developement/ld70.jpeg";
import Ld71 from "../assets/images/Land-Developement/ld71.jpeg";
import Ld72 from "../assets/images/Land-Developement/ld72.jpeg";
import Ld73 from "../assets/images/Land-Developement/ld73.jpeg";
import Ld74 from "../assets/images/Land-Developement/ld74.jpeg";
import Ld75 from "../assets/images/Land-Developement/ld75.jpeg";
import Ld76 from "../assets/images/Land-Developement/ld76.jpeg";
import Ld77 from "../assets/images/Land-Developement/ld77.jpeg";
import Ld78 from "../assets/images/Land-Developement/ld78.jpeg";
import Ld79 from "../assets/images/Land-Developement/ld79.jpeg";
import Ld80 from "../assets/images/Land-Developement/ld80.jpeg";
import Ld81 from "../assets/images/Land-Developement/ld81.jpeg";
import Ld82 from "../assets/images/Land-Developement/ld82.jpeg";
import Ld83 from "../assets/images/Land-Developement/ld83.jpeg";
import Ld84 from "../assets/images/Land-Developement/ld84.jpeg";
import Ld85 from "../assets/images/Land-Developement/ld85.jpeg";
import Ld86 from "../assets/images/Land-Developement/ld86.jpeg";
import Ld87 from "../assets/images/Land-Developement/ld87.jpeg";
import Ld88 from "../assets/images/Land-Developement/ld88.jpeg";
import Ld89 from "../assets/images/Land-Developement/ld89.jpeg";
import Ld90 from "../assets/images/Land-Developement/ld90.jpeg";
import Ld91 from "../assets/images/Land-Developement/ld91.jpeg";
import Ld92 from "../assets/images/Land-Developement/ld92.jpeg";
import Ld93 from "../assets/images/Land-Developement/ld93.jpeg";
import Ld94 from "../assets/images/Land-Developement/ld94.jpeg";
import Ld95 from "../assets/images/Land-Developement/ld95.jpeg";
import Ld96 from "../assets/images/Land-Developement/ld96.jpeg";
import Ld97 from "../assets/images/Land-Developement/ld97.jpeg";
import Ld98 from "../assets/images/Land-Developement/ld98.jpeg";
import Ld99 from "../assets/images/Land-Developement/ld99.jpeg";
import Ld100 from "../assets/images/Land-Developement/ld100.jpeg";

import planLayout from "../assets/images/plan-2.png";
import planLayout1 from "../assets/images/projects-2-plan.png";
import planLayout2 from "../assets/images/projects-2-plan1.png";

export const properties = [
  {
    id: 2,
    image: Property1,
    name: "Sai Resedency 2",
    square: 630,
    downPayment: "2100/-",
    location: "Muler",
    price: "2,81,000/-",
    layout: [planLayout1, planLayout2],

    // rating: 5.0,
  },
  {
    id: 3,
    image: Property2,
    name: "Sai Resedency 3",
    square: 630,
    downPayment: "2100/-",
    location: "Chanchvel",
    price: "2,81,000/-",

    // rating: 5.0,
  },
];

// export default properties;

export const completed = [
  {
    id: 1,
    image: Property3,
    name: "Sai Resedency 1",
    square: "630",
    downPayment: "2100/-",
    location: "Keshvan",
    price: "1,51,000/-",
    layout: [planLayout],
    mapLocation:
      "http://svmdevelopers.in/wp-content/uploads/2022/06/Booking-Plan.pdf",
    // rating: 5.0,
  },
];

export const Customers = [
  {
    id: 1,
    image: Hc15,
  },
  {
    id: 2,
    image: Hc1,
  },
  {
    id: 3,
    image: Hc2,
  },
  {
    id: 4,
    image: Hc3,
  },
  {
    id: 5,
    image: Hc4,
  },
  {
    id: 6,
    image: Hc5,
  },
  {
    id: 7,
    image: Hc6,
  },
  {
    id: 8,
    image: Hc9,
  },
  {
    id: 9,
    image: Hc16,
  },
  {
    id: 10,
    image: Hc10,
  },
  {
    id: 11,
    image: Hc12,
  },
  {
    id: 12,
    image: Hc13,
  },
  {
    id: 13,
    image: Hc14,
  },
  {
    id: 14,
    image: Hc7,
  },
  {
    id: 15,
    image: Hc8,
  },
  {
    id: 16,
    image: Hc11,
  },
  {
    id: 17,
    image: Hc17,
  },
  {
    id: 18,
    image: Hc18,
  },

  {
    id: 20,
    image: Hc20,
  },
  {
    id: 21,
    image: Hc21,
  },
  {
    id: 22,
    image: Hc22,
  },
  {
    id: 23,
    image: Hc23,
  },
  {
    id: 24,
    image: Hc24,
  },
  {
    id: 25,
    image: Hc25,
  },
  {
    id: 26,
    image: Hc26,
  },
  {
    id: 27,
    image: Hc27,
  },
  {
    id: 28,
    image: Hc28,
  },
  {
    id: 29,
    image: Hc29,
  },
  {
    id: 30,
    image: Hc30,
  },
  {
    id: 31,
    image: Hc31,
  },
  {
    id: 32,
    image: Hc32,
  },
  {
    id: 33,
    image: Hc33,
  },
  {
    id: 34,
    image: Hc34,
  },
  {
    id: 35,
    image: Hc35,
  },
  {
    id: 36,
    image: Hc36,
  },
  {
    id: 37,
    image: Hc37,
  },
  {
    id: 38,
    image: Hc38,
  },
  {
    id: 39,
    image: Hc39,
  },
  {
    id: 40,
    image: Hc40,
  },
  {
    id: 41,
    image: Hc41,
  },
  {
    id: 42,
    image: Hc42,
  },
  {
    id: 43,
    image: Hc43,
  },
  {
    id: 44,
    image: Hc44,
  },
  {
    id: 45,
    image: Hc45,
  },
  {
    id: 46,
    image: Hc46,
  },
  {
    id: 47,
    image: Hc47,
  },
  {
    id: 48,
    image: Hc48,
  },
  {
    id: 49,
    image: Hc49,
  },
  {
    id: 50,
    image: Hc50,
  },
  {
    id: 51,
    image: Hc51,
  },
  {
    id: 52,
    image: Hc52,
  },
  {
    id: 53,
    image: Hc53,
  },
  {
    id: 54,
    image: Hc54,
  },
  {
    id: 55,
    image: Hc55,
  },
  {
    id: 56,
    image: Hc56,
  },
  {
    id: 57,
    image: Hc57,
  },
  {
    id: 58,
    image: Hc58,
  },
  {
    id: 59,
    image: Hc59,
  },
  {
    id: 60,
    image: Hc60,
  },
  {
    id: 61,
    image: Hc61,
  },
  {
    id: 62,
    image: Hc62,
  },
  {
    id: 63,
    image: Hc63,
  },
  {
    id: 64,
    image: Hc64,
  },
  {
    id: 65,
    image: Hc65,
  },
  {
    id: 66,
    image: Hc66,
  },
  {
    id: 67,
    image: Hc67,
  },
  {
    id: 68,
    image: Hc68,
  },
  {
    id: 69,
    image: Hc69,
  },
  {
    id: 70,
    image: Hc70,
  },
  {
    id: 71,
    image: Hc71,
  },
  {
    id: 72,
    image: Hc72,
  },
  {
    id: 73,
    image: Hc73,
  },
  {
    id: 74,
    image: Hc74,
  },
  {
    id: 75,
    image: Hc75,
  },
  {
    id: 77,
    image: Hc77,
  },
  {
    id: 78,
    image: Hc78,
  },
  {
    id: 79,
    image: Hc79,
  },
  {
    id: 80,
    image: Hc80,
  },
  {
    id: 81,
    image: Hc81,
  },
  {
    id: 82,
    image: Hc82,
  },
  {
    id: 83,
    image: Hc83,
  },
  {
    id: 84,
    image: Hc84,
  },
  {
    id: 85,
    image: Hc85,
  },
  {
    id: 86,
    image: Hc86,
  },
  {
    id: 87,
    image: Hc87,
  },
  {
    id: 88,
    image: Hc88,
  },
  {
    id: 89,
    image: Hc89,
  },
  {
    id: 90,
    image: Hc90,
  },
  {
    id: 91,
    image: Hc91,
  },
  {
    id: 92,
    image: Hc92,
  },
  {
    id: 93,
    image: Hc93,
  },
  {
    id: 94,
    image: Hc94,
  },
  {
    id: 95,
    image: Hc95,
  },
  {
    id: 96,
    image: Hc96,
  },
  {
    id: 97,
    image: Hc97,
  },
  {
    id: 98,
    image: Hc98,
  },
  {
    id: 99,
    image: Hc99,
  },
  {
    id: 100,
    image: Hc100,
  },

  // Add more objects as needed following the same pattern
];

// export const Customers = [
//   {
//     id: 1,
//     image: Hc15,
//   },
//   {
//     id: 2,
//     image: Hc1,
//   },
//   {
//     id: 3,
//     image: Hc2,
//   },
//   {
//     id: 4,
//     image: Hc3,
//   },
//   {
//     id: 5,
//     image: Hc4,
//   },
//   {
//     id: 6,
//     image: Hc5,
//   },
//   {
//     id: 7,
//     image: Hc6,
//   },
//   {
//     id: 8,
//     image: Hc9,
//   },
//   {
//     id: 9,
//     image: Hc16,
//   },
//   {
//     id: 10,
//     image: Hc10,
//   },
//   {
//     id: 11,
//     image: Hc12,
//   },
//   {
//     id: 12,
//     image: Hc13,
//   },
//   {
//     id: 13,
//     image: Hc14,
//   },
//   {
//     id: 14,
//     image: Hc7,
//   },
//   {
//     id: 15,
//     image: Hc8,
//   },
//   {
//     id: 16,
//     image: Hc11,
//   },
// ];

export const Sai2Customer = [
  {
    id: 1,
    image: Hcc1,
  },
  {
    id: 2,
    image: Hcc2,
  },
  {
    id: 3,
    image: Hcc3,
  },
  {
    id: 4,
    image: Hcc4,
  },
  {
    id: 5,
    image: Hcc5,
  },
  {
    id: 6,
    image: Hcc6,
  },
  {
    id: 7,
    image: Hcc7,
  },
  {
    id: 8,
    image: Hcc8,
  },
  {
    id: 9,
    image: Hcc9,
  },
  {
    id: 10,
    image: Hcc10,
  },
  {
    id: 11,
    image: Hcc11,
  },
  {
    id: 12,
    image: Hcc12,
  },
  {
    id: 13,
    image: Hcc13,
  },
  {
    id: 14,
    image: Hcc14,
  },
  {
    id: 15,
    image: Hcc15,
  },
  {
    id: 16,
    image: Hcc16,
  },
  {
    id: 17,
    image: Hcc17,
  },
  {
    id: 18,
    image: Hcc18,
  },
  {
    id: 19,
    image: Hcc19,
  },
  {
    id: 20,
    image: Hcc20,
  },
  {
    id: 21,
    image: Hcc21,
  },
  {
    id: 22,
    image: Hcc22,
  },
  {
    id: 23,
    image: Hcc23,
  },
  {
    id: 24,
    image: Hcc24,
  },
  {
    id: 25,
    image: Hcc25,
  },
  {
    id: 26,
    image: Hcc26,
  },
  {
    id: 27,
    image: Hcc27,
  },
  {
    id: 28,
    image: Hcc28,
  },
  {
    id: 29,
    image: Hcc29,
  },
  {
    id: 30,
    image: Hcc30,
  },
  {
    id: 31,
    image: Hcc31,
  },
  {
    id: 32,
    image: Hcc32,
  },
  {
    id: 33,
    image: Hcc33,
  },
  {
    id: 34,
    image: Hcc34,
  },
  {
    id: 35,
    image: Hcc35,
  },
  {
    id: 36,
    image: Hcc36,
  },
  {
    id: 37,
    image: Hcc37,
  },
  {
    id: 38,
    image: Hcc38,
  },
  {
    id: 39,
    image: Hcc39,
  },
  {
    id: 40,
    image: Hcc40,
  },
  {
    id: 41,
    image: Hcc41,
  },
  {
    id: 42,
    image: Hcc42,
  },
  {
    id: 43,
    image: Hcc43,
  },
  {
    id: 44,
    image: Hcc44,
  },
  {
    id: 45,
    image: Hcc45,
  },
  {
    id: 46,
    image: Hcc46,
  },
  {
    id: 47,
    image: Hcc47,
  },
  {
    id: 48,
    image: Hcc48,
  },
  {
    id: 49,
    image: Hcc49,
  },
  {
    id: 50,
    image: Hcc50,
  },
  {
    id: 51,
    image: Hcc52,
  },
  {
    id: 53,
    image: Hcc53,
  },
  {
    id: 54,
    image: Hcc54,
  },
  {
    id: 55,
    image: Hcc55,
  },
  {
    id: 56,
    image: Hcc56,
  },
  {
    id: 57,
    image: Hcc57,
  },
  {
    id: 58,
    image: Hcc58,
  },
  {
    id: 59,
    image: Hcc59,
  },
  {
    id: 60,
    image: Hcc60,
  },
  {
    id: 61,
    image: Hcc61,
  },
  {
    id: 62,
    image: Hcc62,
  },
  {
    id: 63,
    image: Hcc63,
  },
  {
    id: 64,
    image: Hcc64,
  },
  {
    id: 65,
    image: Hcc65,
  },
  {
    id: 66,
    image: Hcc66,
  },
  {
    id: 67,
    image: Hcc67,
  },
  {
    id: 68,
    image: Hcc68,
  },
  {
    id: 69,
    image: Hcc69,
  },
  {
    id: 70,
    image: Hcc70,
  },
  {
    id: 71,
    image: Hcc71,
  },
  {
    id: 72,
    image: Hcc72,
  },
  {
    id: 73,
    image: Hcc73,
  },
  {
    id: 74,
    image: Hcc74,
  },
  {
    id: 75,
    image: Hcc75,
  },
  {
    id: 76,
    image: Hcc76,
  },
  {
    id: 77,
    image: Hcc77,
  },
  {
    id: 78,
    image: Hcc78,
  },
  {
    id: 79,
    image: Hcc79,
  },
  {
    id: 80,
    image: Hcc80,
  },
  {
    id: 81,
    image: Hcc81,
  },
  {
    id: 82,
    image: Hcc82,
  },
  {
    id: 83,
    image: Hcc83,
  },
  {
    id: 84,
    image: Hcc84,
  },
  {
    id: 85,
    image: Hcc85,
  },
  {
    id: 86,
    image: Hcc86,
  },
  {
    id: 87,
    image: Hcc87,
  },
  {
    id: 88,
    image: Hcc88,
  },
];

export const Sai3Customer = [
  {
    id: 1,
    image: HCC1,
  },
  {
    id: 2,
    image: HCC2,
  },
  {
    id: 3,
    image: HCC3,
  },
];

export const PlanElevation = [
  {
    id: 1,
    image: Image1,
  },
  {
    id: 2,
    image: Image2,
  },
  {
    id: 3,
    image: Image3,
  },
  {
    id: 4,
    image: Image4,
  },
];

export const LandDevelopement = [
  {
    id: 1,
    image: Ld1,
  },
  {
    id: 2,
    image: Ld2,
  },
  {
    id: 3,
    image: Ld3,
  },
  {
    id: 4,
    image: Ld4,
  },
  {
    id: 5,
    image: Ld5,
  },
  {
    id: 6,
    image: Ld6,
  },
  {
    id: 7,
    image: Ld8,
  },
  {
    id: 8,
    image: Ld8,
  },
  {
    id: 9,
    image: Ld9,
  },
  {
    id: 10,
    image: Ld10,
  },
  {
    id: 11,
    image: Ld11,
  },
  {
    id: 12,
    image: Ld12,
  },
  {
    id: 13,
    image: Ld13,
  },
  {
    id: 14,
    image: Ld14,
  },
  {
    id: 15,
    image: Ld15,
  },
  {
    id: 16,
    image: Ld17,
  },
  {
    id: 17,
    image: Ld17,
  },
  {
    id: 18,
    image: Ld18,
  },
  {
    id: 19,
    image: Ld19,
  },
  {
    id: 20,
    image: Ld20,
  },
  {
    id: 21,
    image: Ld21,
  },
  {
    id: 22,
    image: Ld22,
  },
  {
    id: 23,
    image: Ld23,
  },
  {
    id: 24,
    image: Ld24,
  },
  {
    id: 25,
    image: Ld25,
  },
  {
    id: 26,
    image: Ld26,
  },
  {
    id: 27,
    image: Ld27,
  },
  {
    id: 28,
    image: Ld28,
  },
  {
    id: 29,
    image: Ld29,
  },
  {
    id: 30,
    image: Ld30,
  },
  {
    id: 31,
    image: Ld31,
  },
  {
    id: 32,
    image: Ld32,
  },
  {
    id: 33,
    image: Ld33,
  },
  {
    id: 34,
    image: Ld34,
  },
  {
    id: 35,
    image: Ld35,
  },
  {
    id: 36,
    image: Ld36,
  },
  {
    id: 37,
    image: Ld37,
  },
  {
    id: 38,
    image: Ld38,
  },
  {
    id: 39,
    image: Ld39,
  },
  {
    id: 40,
    image: Ld40,
  },
  {
    id: 41,
    image: Ld41,
  },
  {
    id: 42,
    image: Ld42,
  },
  {
    id: 43,
    image: Ld43,
  },
  {
    id: 44,
    image: Ld44,
  },
  {
    id: 45,
    image: Ld45,
  },
  {
    id: 46,
    image: Ld46,
  },
  {
    id: 47,
    image: Ld47,
  },
  {
    id: 48,
    image: Ld48,
  },
  {
    id: 49,
    image: Ld49,
  },
  {
    id: 50,
    image: Ld50,
  },
  {
    id: 51,
    image: Ld51,
  },
  {
    id: 52,
    image: Ld52,
  },
  {
    id: 53,
    image: Ld53,
  },
  {
    id: 54,
    image: Ld54,
  },
  {
    id: 55,
    image: Ld55,
  },
  {
    id: 56,
    image: Ld56,
  },
  {
    id: 57,
    image: Ld57,
  },
  {
    id: 58,
    image: Ld58,
  },
  {
    id: 59,
    image: Ld59,
  },
  {
    id: 60,
    image: Ld60,
  },
  {
    id: 61,
    image: Ld61,
  },
  {
    id: 62,
    image: Ld62,
  },
  {
    id: 63,
    image: Ld63,
  },
  {
    id: 64,
    image: Ld64,
  },
  {
    id: 65,
    image: Ld65,
  },
  {
    id: 66,
    image: Ld66,
  },
  {
    id: 67,
    image: Ld67,
  },
  {
    id: 68,
    image: Ld68,
  },
  {
    id: 69,
    image: Ld69,
  },
  {
    id: 70,
    image: Ld70,
  },
  {
    id: 71,
    image: Ld71,
  },
  {
    id: 72,
    image: Ld72,
  },
  {
    id: 73,
    image: Ld73,
  },
  {
    id: 74,
    image: Ld74,
  },
  {
    id: 75,
    image: Ld75,
  },
  {
    id: 76,
    image: Ld76,
  },
  {
    id: 77,
    image: Ld77,
  },
  {
    id: 78,
    image: Ld78,
  },
  {
    id: 79,
    image: Ld79,
  },
  {
    id: 80,
    image: Ld80,
  },
  {
    id: 81,
    image: Ld81,
  },
  {
    id: 82,
    image: Ld82,
  },
  {
    id: 83,
    image: Ld83,
  },
  {
    id: 84,
    image: Ld84,
  },
  {
    id: 85,
    image: Ld85,
  },
  {
    id: 86,
    image: Ld86,
  },
  {
    id: 87,
    image: Ld87,
  },
  {
    id: 88,
    image: Ld88,
  },
  {
    id: 89,
    image: Ld89,
  },
  {
    id: 90,
    image: Ld90,
  },
  {
    id: 91,
    image: Ld91,
  },
  {
    id: 92,
    image: Ld92,
  },
  {
    id: 93,
    image: Ld93,
  },
  {
    id: 94,
    image: Ld94,
  },
  {
    id: 95,
    image: Ld95,
  },
  {
    id: 96,
    image: Ld96,
  },
  {
    id: 97,
    image: Ld97,
  },
  {
    id: 98,
    image: Ld98,
  },
  {
    id: 99,
    image: Ld99,
  },
  {
    id: 100,
    image: Ld100,
  },
];

export const ProjectPlannLayouts = [
  {
    id: 1,
    layout: planLayout,
    link: "http://svmdevelopers.in/wp-content/uploads/2022/06/Booking-Plan.pdf",
  },
  {
    id: 2,
    layout: planLayout1,
    link: "http://svmdevelopers.in/wp-content/uploads/2023/02/B-.NO_.65-LAY-OUT-PLAN.pdf",
  },
  {
    id: 3,
    layout: planLayout,
    link: "http://svmdevelopers.in/wp-content/uploads/2022/06/Booking-Plan.pdf",
  },
];

export const SR1Layout =
  "https://svm-bucket.blr1.cdn.digitaloceanspaces.com/planningAndLayout/Booking-Plan.pdf";
export const SR2Layout =
  "https://svm-bucket.blr1.cdn.digitaloceanspaces.com/planningAndLayout/B-.NO_.65-LAY-OUT-PLAN.pdf";
export const SR21Layout =
  "https://svm-bucket.blr1.cdn.digitaloceanspaces.com/planningAndLayout/B-.NO_.66-LAY-OUT-PLAN.pdf";

export const Sr3Layout =
  "https://svm-bucket.blr1.cdn.digitaloceanspaces.com/planningAndLayout/Dastavej%20Layout%20plan.pdf";
